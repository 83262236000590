// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyCgaeZXp9pX-07UyV5XJVSgtTHrXg1YwEw",
  authDomain: "billnew1-45c8a.firebaseapp.com",
  databaseURL: "https://billnew1-45c8a-default-rtdb.firebaseio.com",
  projectId: "billnew1-45c8a",
  storageBucket: "billnew1-45c8a.appspot.com",
  messagingSenderId: "134958213576",
  appId: "1:134958213576:web:23780dea1ed74b8fb378fd",
  measurementId: "G-DX2V12WEVN"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };